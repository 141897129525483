import React, { useEffect, useState, useMemo } from 'react';
import {
  calRotationYDefaultAndCenterLookAt,
  updateVector3OrPointByRotationXZ,
} from 'utils/positionHelper';

const MAX_ANGLE = (2 * Math.PI) / 3;
const MIN_ANGLE = Math.PI / 6;
const CENTER_CIRCLE = { x: 25, y: 25 };
const RADIUS = 25;
const UPPER_BOUND = 60;
const LOWER_BOUND = 30;

export default function MinorSector({
  // defaultRotation,
  cameraParams,
  currentPano,
  sceneRotation,
}) {
  const [startPoint, setStartPoint] = useState(null);
  const [endPoint, setEndPoint] = useState(null);
  const [sweepAngle, setSweepAngle] = useState(MAX_ANGLE);

  const { defaultOrientation } = currentPano;

  const defaultRotation = useMemo(() => {
    if (currentPano.id === 'can-ho-1pn-scene-1') {
      return 5.3;
    }

    if (currentPano.id === '6') {
      return 4.8;
    }

    return undefined;
  }, [currentPano]);

  useEffect(() => {
    setStartPoint(null);
  }, [defaultRotation]);

  const defaultOrientationAdj = useMemo(() => {
    if (currentPano && sceneRotation?.prevRot) {
      return updateVector3OrPointByRotationXZ(
        currentPano?.defaultOrientation,
        sceneRotation?.prevRot?.rad || 0,
        false
      );
    }
    return defaultOrientation;
  }, [currentPano, defaultOrientation, sceneRotation]);

  const { lookAtPoint, fov } = useMemo(() => {
    if (cameraParams?.lookAtPoint && cameraParams?.fov) {
      return { lookAtPoint: cameraParams.lookAtPoint, fov: cameraParams.fov };
    }
    return { lookAtPoint: defaultOrientationAdj, fov: 60 };
  }, [cameraParams, defaultOrientationAdj]);

  const currRotRad = useMemo(() => {
    return sceneRotation?.currRotation?.rad || 0;
  }, [sceneRotation]);

  const rotationY = useMemo(() => {
    const rotationY = calRotationYDefaultAndCenterLookAt(
      defaultOrientation,
      lookAtPoint
    );
    return rotationY;
  }, [defaultOrientation, lookAtPoint]);

  useEffect(() => {
    if (fov !== null && fov !== undefined) {
      if (fov < UPPER_BOUND && fov > LOWER_BOUND) {
        const slope = (MAX_ANGLE - MIN_ANGLE) / (UPPER_BOUND - LOWER_BOUND);
        const intercept = MIN_ANGLE - slope * LOWER_BOUND;
        setSweepAngle(slope * fov + intercept);
      } else if (fov <= LOWER_BOUND) {
        setSweepAngle(MIN_ANGLE);
      } else {
        setSweepAngle(MAX_ANGLE);
      }
    }
  }, [fov]);

  useEffect(() => {
    if (defaultRotation && rotationY && fov) {
      const startX =
        CENTER_CIRCLE.x +
        RADIUS *
          Math.cos(defaultRotation + rotationY + currRotRad - sweepAngle / 2);
      const startY =
        CENTER_CIRCLE.y +
        RADIUS *
          Math.sin(defaultRotation + rotationY + currRotRad - sweepAngle / 2);
      const endX =
        CENTER_CIRCLE.x +
        RADIUS *
          Math.cos(defaultRotation + rotationY + currRotRad + sweepAngle / 2);
      const endY =
        CENTER_CIRCLE.y +
        RADIUS *
          Math.sin(defaultRotation + rotationY + currRotRad + sweepAngle / 2);
      setStartPoint({ x: startX, y: startY });
      setEndPoint({ x: endX, y: endY });
    }
  }, [currRotRad, defaultRotation, fov, rotationY, sweepAngle]);

  // console.log(startPoint, endPoint);

  return (
    <>
      {startPoint && endPoint && (
        <svg width="50" height="50" xmlns="http://www.w3.org/2000/svg">
          <path
            d={`M ${CENTER_CIRCLE.x} ${CENTER_CIRCLE.y} L ${startPoint.x} ${startPoint.y} A ${RADIUS} ${RADIUS} 0 0 1 ${endPoint.x} ${endPoint.y} Z`}
            stroke="rgb(252,89,56)"
            strokeWidth="0"
            strokeOpacity="0.3"
            fill="rgb(252,89,56)"
            fillOpacity="0.5"
          ></path>
        </svg>
      )}
    </>
  );
}
